import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tech',
  templateUrl: './tech.component.html',
  styleUrls: ['./tech.component.scss']
})
export class TechComponent implements OnInit {

  title = 'Golf Course Operators Software | Golf Course Management Software and POS | Golf Course Management Tools - Izontechnology.com';

  constructor(private router: Router, private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.metaService.updateTag(
      { name: 'keywords', content: 'Izontechnology, Izontechnology.com, Golf Course Management, POS integrations, Golf Course Management Software, Golf Course Management Tools, GOLF course operators, golf course  management services, golf course services, Golf Course Operators Software, IZON Technology, IZON Technology USA, Golf course operator software tools, Izon Solutions' }
    );

    this.metaService.updateTag({ name: 'description', content: 'Izon Technology is a Golf course management software solution for GOLF course operators to expand their revenue-driving through engaging consumer experiences. Izon tech provides the services inn the USA through IZON Golf, Izon Stream, and Izon Ride - Izontechnology.com' }
    );

    let url = this.router.url == "/home" ? "https://izontechnology.com/home" : "https://izontechnology.com/";
    this.updateCanonicalUrl(url);
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

}
