import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-ride',
  templateUrl: './ride.component.html',
  styleUrls: ['./ride.component.scss']
})
export class RideComponent implements OnInit {

  title = 'Izon Ride | Izon Technology | Golf Course Operators Tools - IzonTechnology.com';

  constructor(private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.metaService.updateTag({ name: 'keywords', content: 'Izontechnology, Izontechnology.com, Golf Course Management, POS integrations, Golf Course Management Software, Golf Course Management Tools, GOLF course operators, golf course  management services, golf course services, Golf Course Operators Software, IZON Technology, IZON Technology USA, Golf course operator software tools, Izon Solutions, Izon GOLF, Izon Stream, Izon Ride' });

    this.metaService.updateTag({ name: 'description', content: 'Izon Technology introduces the Izon Ride is the fastest-growing in-rideshare screen experience in North America and our golf technology helps to connect the consumers and brands off the course. Also, providing engaging hyper-local relevant offers, live content, games & rewards - IzonTechnology.com' });

    this.updateCanonicalUrl("https://izontechnology.com/ride");
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }
}
