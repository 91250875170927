import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TechComponent } from './tech/tech.component';
import { GolfComponent } from './golf/golf.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { StreamComponent } from './stream/stream.component';
import { RideComponent } from './ride/ride.component';
import { PagenotfoundsComponent } from './pagenotfounds/pagenotfounds.component';
import { ReachComponent } from './reach/reach.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { WebinarComponent } from './webinar/webinar/webinar.component';
import { TermsComponent } from './terms/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    TechComponent,
    GolfComponent,
    SolutionsComponent,
    StreamComponent,
    RideComponent,
    PagenotfoundsComponent,
    ReachComponent,
    AnalyticsComponent,
    WebinarComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
