import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnInit {

  title = 'Izon Stream | Real-Time Golf Clubhouse Tv Experience | Golf Course Operators Tools - IzonTechnology.com';

  constructor(private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.metaService.updateTag({ name: 'keywords', content: 'Izontechnology, Izontechnology.com, Golf Course Management, POS integrations, Golf Course Management Software, Golf Course Management Tools, GOLF course operators, golf course  management services, golf course services, Golf Course Operators Software, IZON Technology, IZON Technology USA, Golf course operator software tools, Izon Solutions, Izon GOLF, Izon Stream' });

    this.metaService.updateTag({ name: 'description', content: 'Izon Technology has introduced an Izon Stream golf course management software that creates the ultimate clubhouse TV experience for golf course operators. Also, Golf Operators can cut expensive cable costs, advertise their own on property products and FINALLY share in the advertising revenue - IzonTechnology.com' });

    this.updateCanonicalUrl("https://izontechnology.com/stream");
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }
}
