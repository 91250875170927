import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

declare var hbspt: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {

  @ViewChild('closebutton', { static: true }) closebutton;
  StrContactform: FormGroup;
  Golf360form: FormGroup;
  loading = false;
  submitted = false;
  submitted1 = false;
  errorMsg: any = '';
  errorMsg1:any='';
  Countries: any = [];
  States: any = [];
  selectedState: any = '';
  selectedCountry: any = 'US';
  constructor(private formBuilder: FormBuilder, private http: HttpClient, public router: Router) { }

  ngAfterContentInit(): void {
    hbspt.forms.create({
      region: "na1",
      portalId: "40232980",
      formId: "bea1c89c-83ec-4d95-b5a2-bbf8d3f2b0bb",
      target: '#contactForm'
    });
  }

  ngOnInit() {
    this.StrContactform = this.formBuilder.group({
      IQ_FIRSTNAME: ['', Validators.required],
      IQ_LASTNAME: ['', Validators.required],
      IQ_EMAIL: ['', [Validators.required]],
      IQ_PHONE: ['', [Validators.required]],
      IQ_COMPANY: [''],
      IQ_WEBSITE: [''],
      IQ_MESSAGE: [''],
      radioFilter: ['E']
    });

    this.Golf360form = this.formBuilder.group({
      radioFilter1: ['Private'],
      IZ_COURSENAME: ['', [Validators.required]],
      IZ_ADDRESS: ['', [Validators.required]],
      IZ_CITY: ['', [Validators.required]],
      IZ_STATE: ['', [Validators.required]],
      IZ_COUNTRY: ['', [Validators.required]],
      IZ_ZIP: ['', [Validators.required]],
      IZ_CONTACT: ['', [Validators.required]],
      IZ_TITLE: ['', [Validators.required]],
      IZ_PHONE: ['', [Validators.required]],
      IZ_EMAIL: ['', [Validators.required]],
      IZ_WEBSITE: [''],
      IZ_REFERENCE: ['', [Validators.required]],
      IZ_HOLES: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      IZ_CARTS: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      radioFilter2: ['E']
    })

    this.getCountries();
    this.getStates();

  }
  get f() { return this.StrContactform.controls; }

  get f1() { return this.Golf360form.controls; }

  mobileFormat(e) {
    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
    }
    if (e.target.value.length != "") {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');

      if (!e.target.value) { return ''; }

      var number = String(e.target.value);
      e.target.value = number;

      var front = number.substring(0, 3);
      var center = number.substring(3, 6);
      var end = number.substring(6, 10);

      if (center) {
        e.target.value = (front + "-" + center);
      }
      if (end) {
        e.target.value += ("-" + end);
      }
      return e.target.value;
    }
  }

  close() {
    this.StrContactform.reset();
    this.errorMsg = "";
    this.errorMsg1 = "";
    this.StrContactform = this.formBuilder.group({
      IQ_FIRSTNAME: ['', Validators.required],
      IQ_LASTNAME: ['', Validators.required],
      IQ_EMAIL: ['', [Validators.required]],
      IQ_PHONE: ['', [Validators.required]],
      IQ_COMPANY: [''],
      IQ_WEBSITE: [''],
      IQ_MESSAGE: [''],
      radioFilter: ['E']
    });

    this.submitted = false;
  }

  closeGolf(){
    this.Golf360form.reset();
    this.errorMsg = "";
    this.errorMsg1 = "";
    this.Golf360form = this.formBuilder.group({
      radioFilter1: ['Private'],
      IZ_COURSENAME: ['', [Validators.required]],
      IZ_ADDRESS: ['', [Validators.required]],
      IZ_CITY: ['', [Validators.required]],
      IZ_STATE: ['', [Validators.required]],
      IZ_COUNTRY: ['US', [Validators.required]],
      IZ_ZIP: ['', [Validators.required]],
      IZ_CONTACT: ['', [Validators.required]],
      IZ_TITLE: ['', [Validators.required]],
      IZ_PHONE: ['', [Validators.required]],
      IZ_EMAIL: ['', [Validators.required]],
      IZ_WEBSITE: [''],
      IZ_REFERENCE: ['', [Validators.required]],
      IZ_HOLES: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      IZ_CARTS: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      radioFilter2: ['E']
    })
    this.submitted1 = false;
     this.selectedCountry='US';
    this.getStates();
  }
  onSubmit() {
    this.submitted = true;
    if (this.StrContactform.valid) {
      this.loading = true;
      this.http.post<any>('https://adminapi.izongolf.com/IZONAdmin.svc/saveContactInfo', {
        "action": 'A', "id": 0, "fname": this.StrContactform.controls.IQ_FIRSTNAME.value,
        "lname": this.StrContactform.controls.IQ_LASTNAME.value,
        "email": this.StrContactform.controls.IQ_EMAIL.value,
        "website": this.StrContactform.controls.IQ_WEBSITE.value == null ? "" : this.StrContactform.controls.IQ_WEBSITE.value,
        "phone": this.StrContactform.controls.IQ_PHONE.value,
        "company": this.StrContactform.controls.IQ_COMPANY.value == null ? "" : this.StrContactform.controls.IQ_COMPANY.value,
        "message": this.StrContactform.controls.IQ_MESSAGE.value == null ? "" : this.StrContactform.controls.IQ_MESSAGE.value,
        "contactpreference": this.StrContactform.controls.radioFilter.value,

        "formType": ""
      }).subscribe({
        next: data => {
          if (data[0] > 0) {
            this.close();
            this.errorMsg = "We will be in touch with you shortly.";
            this.loading = false;
            setInterval(() => {
              this.errorMsg = "";
              // this.closebutton.nativeElement.click();
            }, 4000);

          }
          else if (data[0] == -2) {
            this.loading = false;
            this.errorMsg = "Contact Already Exist.";
          }
          else {
            this.loading = false;
            this.errorMsg = "Unable to add contact information.";
          }
        },
        error: error => {
          this.loading = false;
          this.errorMsg = "Unable to add contact information.";
        }
      })
    }
  }

  golfFormSubmit() {
    this.submitted1 = true;
    if (this.Golf360form.valid) {
      this.submitted1 = false;
      const Obj = {
        "action": "A",
        "coursetype": this.Golf360form.value.radioFilter1,
        "coursename": this.Golf360form.value.IZ_COURSENAME,
        "address": this.Golf360form.value.IZ_ADDRESS,
        "city": this.Golf360form.value.IZ_CITY,
        "state": this.Golf360form.value.IZ_STATE,
        "country": this.Golf360form.value.IZ_COUNTRY,
        "zip": this.Golf360form.value.IZ_ZIP,
        "contactname": this.Golf360form.value.IZ_CONTACT,
        "title": this.Golf360form.value.IZ_TITLE,
        "phone": this.Golf360form.value.IZ_PHONE,
        "email": this.Golf360form.value.IZ_EMAIL,
        "website": this.Golf360form.value.IZ_WEBSITE,
        "referredby": this.Golf360form.value.IZ_REFERENCE,
        "noofholes": this.Golf360form.value.IZ_HOLES,
        "noofcarts": this.Golf360form.value.IZ_CARTS,
        "contactpreference": this.Golf360form.value.radioFilter2
      }
      console.log(Obj);

      this.http.post<any>('https://adminapi.izongolf.com/IZONAdmin.svc/saveGolfThreeSixtyInfo', Obj, {
      }).subscribe({
        next: res => {
        if (parseInt(res[0]) > 0) {
          this.closeGolf();
          this.errorMsg1 = "We will be in touch with you shortly.";
          this.loading = false;
          setInterval(() => {
            this.errorMsg1 = "";
            // this.closebutton.nativeElement.click();
          }, 4000);

        }
        else if (parseInt(res[0]) == -2) {
          this.loading = false;
          this.errorMsg1 = "Golf 360 Already Exist.";
        }
        else {
          this.loading = false;
          this.errorMsg1 = "Unable to add Golf 360 information.";
        }
      },
      error: error => {
        this.loading = false;
        this.errorMsg1 = "Unable to add Golf 360 information.";
      }
    })
    }

  }

  getCountries() {
    this.http.post<any>('https://adminapi.izongolf.com/IZONAdmin.svc/getcountrys', {
      "searchvalue": ""
    }).subscribe(res => {
      console.log(res);
      this.Countries = res;
    })
  }

  onCountryChange(targetValue:any){
    this.States=[];
    this.selectedState='';
    this.getStates();
  }

  getStates() {
    this.http.post<any>('https://adminapi.izongolf.com/IZONAdmin.svc/getstates', {
      "searchvalue": "where s_country_id='"+this.selectedCountry+"'"
    }).subscribe(res => {
      if(res.length >0){
      this.States = res;
      }
    })
  }
}
