import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

  title = 'Izon NFT Agency | Golf Course Operators Software Solution | Golf Software Tools - IzonTechnology.com';

  constructor(private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.metaService.updateTag({ name: 'keywords', content: 'Izontechnology, Izontechnology.com, Golf Course Management, POS integrations, Golf Course Management Software, Golf Course Management Tools, GOLF course operators, golf course management services, golf course services, Golf Course Operators Software, IZON Technology, IZON Technology USA, Golf course operator software tools, Izon NFT Agency' });

    this.metaService.updateTag({ name: 'description', content: 'Izon NFT Agency is a completely new approach for GOLF course operators to expand their revenue-driving through engaging consumer experiences by Izon Technology. Our focus is on being the thought leaders for everything from NFT creation, crypto, digital event books, traditional scorecards, AI to POS integrations, and more. - Izontechnology.com' });

    this.updateCanonicalUrl("https://izontechnology.com/nft-agency");
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }
}
