import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-golf',
  templateUrl: './golf.component.html',
  styleUrls: ['./golf.component.scss']
})
export class GolfComponent implements OnInit {

  title = 'Izon GOLF | Golf Course Management Tools | Golf Course Operators Software Tools - IzonTechnology.com';

  constructor(private router:Router,private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.metaService.updateTag({ name: 'keywords', content: 'Izontechnology, Izontechnology.com, Golf Course Management, POS integrations, Golf Course Management Software, Golf Course Management Tools, GOLF course operators, golf course  management services, golf course services, Golf Course Operators Software, IZON Technology, IZON Technology USA, Golf course operator software tools, Izon Solutions, Izon GOLF, Izon Stream' });

    this.metaService.updateTag({ name: 'description', content: 'Izon GOLF is a superior experience for your golfers and an essential set of golf course management tools for operators. Izon Golf provides direct communication from Course staff to customers while on the property, full fleet management, operator-controlled geo-fencing, and Business Intelligence in real-time - IzonTechnology.com' });

    this.updateCanonicalUrl("https://izontechnology.com/golf");
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

  gotoReach():void{
    
  }
}
