import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { GolfComponent } from './golf/golf.component';
import { RideComponent } from './ride/ride.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { StreamComponent } from './stream/stream.component';
import { TechComponent } from './tech/tech.component';
import { PagenotfoundsComponent } from './pagenotfounds/pagenotfounds.component';
import { ReachComponent } from './reach/reach.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { WebinarComponent } from './webinar/webinar/webinar.component';
import { TermsComponent } from './terms/terms/terms.component';


const routes: Routes = [

  { path: "home", component: TechComponent },

  { path: "golf-360", component: GolfComponent },

  { path: "izon3-agency", component: SolutionsComponent },

  { path: "webinar", component: WebinarComponent },

  { path: "terms", component: TermsComponent },

  { path: "stream", component: StreamComponent },

  { path: "ride", component: RideComponent },

  { path: "reach", component: ReachComponent },

  { path: "analytics", component: AnalyticsComponent },

  { path: "404", component: PagenotfoundsComponent, data: { preload: true } },

  { path: "", component: TechComponent, pathMatch: 'full', data: { preload: false } },

  { path: "**", redirectTo: '404', pathMatch: 'full' }

];
const config: ExtraOptions = {
  useHash: false,
  enableTracing: false // Turn this on to log routing events to the console
};
@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
